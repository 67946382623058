<template>
    <InventoryMetersComp/>
</template>

<script>
export default {
  name: "InventoryMeters",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Inventory Meters", path:"", isActive: true}] 
    }
  },
  components: {
    InventoryMetersComp: () => import("../../src/components/inventory/Meters"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>